.service {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  opacity: 0;
  transform: translateY(20px); 
  transition: opacity 2s ease, transform 2s ease; 
}

.service.visible {
  opacity: 1; 
  transform: translateY(0); 
}

.service > div > img {
  border-radius: 10px;
  width: 400px;
  height: 300px;
  max-width: 90vw;
}
.service > div > img:hover {
  opacity: 0.5;
  transition: opacity 0.1s ease;
}

.last {
  min-width: 40vw;
}
