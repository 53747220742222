.sub-header {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 50px;
  font-size: 2rem;
}

.sub-header > img{
  width: 270px;
  height: 100px;
}

.button-logo {
  width: 30px;
  height: 30px;
}