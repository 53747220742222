@import url('Colors.css');

.footer {
  background-color: var(--footer-color);
  color: white;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0 0;
}

.footer-upper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 80%;
}

.footer-map {
  display: flex;
}

.footer-map > a > li {
  color: white;
  list-style: none;
}

.footer-lower {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}
