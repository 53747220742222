@import url('./stylesheets/Colors.css');


a {
  text-decoration: none;
  margin: 0 10px;
}

ul {
  padding-inline-start: 0;
}

.contact-button {
  background-color: var(--primary-color);
  color: white;
  padding: 1px 15px;
  text-align: center;
  font-family: "Inder", sans-serif;
  font-weight: 400;
  cursor: pointer;
  border-radius: 12px;
  min-width: 160px;
  margin: 0;
}

.contact-button:hover {
  background-color: var(--secondary-color);
  transition: background-color 0.8s ease;
  color: black;
  transition: color 0.8s ease;
}


.sub-header-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.1rem;
  min-width: 130px;
  padding: 8px;
  margin-top: 10px;
}

.whatsapp-logo {
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 100px;
  height: 100px;
  z-index: 5;
}

@media (max-width: 600px) {
  .whatsapp-logo {
    width: 60px;
    height: 60px;
  }
}