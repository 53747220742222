/* :root {
  --primary-color: #962715;
  --secondary-color: #F1DCC9;
  --footer-color: #42313A;
} */
/* :root {
  --primary-color: #962715;
  --secondary-color: #FAEFD4;
  --footer-color: #42313A;
} */
:root {
  --primary-color: #962715;
  --secondary-color: #E4E3DB;
  --footer-color: #42313A;
}