@import url('Colors.css');

.header {
  background-color: var(--primary-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.header-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

@media (max-width: 600px) {
  .header-items {
    justify-content: center;
  }
}

.header-logo {
  width: 30px;
  height: 30px;
  color: white;
}

.bottom {
  padding: 0 0 2px;
  background-color: var(--footer-color);
  justify-content: center;
}