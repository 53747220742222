@import url('Colors.css');

.slide-show {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0 20px;
}

.slide img {
  border-radius: 10px;
  max-width: 30vw;
  min-width: 30vw;
  max-height: 90vh;
  min-height: 90vh;
  
}

@media (max-width: 600px) {
  .slide img {
  max-width: 75vw;
  min-width: 75vw;
  max-height: 80vh;
  min-height: 80vh;
  }
}

.arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 40vh 24vw;
  color: white;
  font-size: 40px;
  user-select: none;
}

@media (max-width: 600px) {
  .arrow {
    font-size: 20px;
    padding: 75% 24%;
  }
}

.arrow.prev {
  left: 0;
}

.arrow.next {
  right: 0;
}

.reviews {
  background-color: var(--primary-color);
  border-radius: 15px;
  color: white;
  margin: 0 28% 1%;
  padding: 5px 20px;
}

@media (max-width: 600px) {
  .reviews {
    margin: 0 11% 3%;
  }
}

.review-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 12px;
  color: black;
  font-weight: 400;
  text-align: center;
  margin: 10px auto;
  padding: 10px;
  width: 25%;
}

@media (max-width: 600px) {
  .review-button {
    width: 50%;
  }
}

.review-button-logo {
  width: 30px;
  height: 30px;
}

.author {
  margin-left: 10%;
}
