.services-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
