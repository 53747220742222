@import url('Colors.css');

.bio {
  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1% 20%;
  text-align: center;
}

@media (max-width: 600px) {
  .bio {
    padding: 0 10% 7%;
  }
}
@media (max-width: 600px) {
  .bio > p {
    margin: 0;
  }
}